.quill{
    box-shadow: 0px 0px 0px 0px #0000000a;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
}
.ql-toolbar .ql-snow {
    border: none !important;
}
.ql-container {
    font-family: inherit !important;
    font-size: inherit !important;
    flex:1 0 auto;
        display: flex;
    flex-direction: column;
}
.ql-container .ql-snow {
    border: none !important;
}

.ql-snow {
    border: none!important; 
} 
.ql-toolbar{
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    border: 0px solid rgb(229 231 235) !important;
}
.ql-editor {
    border-radius: 0 0 10px 10px;
    border: 1px solid rgb(229 231 235) !important;
    flex:1 0 auto;
    font-size: small;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 0 !important;
}
.ql-editor ul[data-checked=true] > li::before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="rgba(53, 129, 235, 0.85)" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>') !important;
    color: #5e72e4 !important;
    
}

.ql-editor ul[data-checked=false] > li::before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="rgba(53, 129, 235, 0.85)" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M 21 12 C 21 18.928 13.5 23.258 7.5 19.794 C 4.715 18.187 3 15.215 3 12 C 3 5.072 10.5 0.742 16.5 4.206 C 19.285 5.813 21 8.785 21 12 Z"></path></svg>') !important;
    fill: #5e72e4 !important;
}
.ql-editor ul[data-checked=true] > li {
    text-decoration: line-through !important;
    display: inline-block;
  
    vertical-align: middle;
}
.ql-editor li {
    font-weight: 400 !important;
}
