
  .pageLoaderCircle{
    height: 100px;
    width:100px;
    border: solid 15px #06b6d4;
    border-radius:1000px;
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    animation: fullRotation 2s infinite;
  }
  .pageLoaderInnerDot{
    position: absolute;
    z-index:500;
    height:20px;
    width:20px;
    background-color:#06b6d4;
    right:-25px;
    top:-25px;
    border-radius:100%;
  
  }
  
  
  .pageLoaderOuterDot{
    position: absolute;
    z-index:500;
    height:20px;
    width:20px;
    background-color:#06b6d4;
    right:10px;
    top:10px;
    border-radius:100%;
  }
  
  @keyframes fullRotation{
    from{
      transform: rotate(0);
    }
    to{
      transform: rotate(360deg);
    }
  }