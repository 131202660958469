@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

@font-face {
    font-family: "Chewer";
    src: url("assets/Chewer.ttf");
}


.landingPage{
    height: 100vh;
    color: #0e2356;
}